@use 'colors.scss';

#host {
    background: url(../resources/host_background.png);
    position: absolute;
    top: 650px;
    width: 222px;
    height: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .host-name {
        font-family: 'edit-undo';
        font-size: 19px;
        text-transform: lowercase;
        color: colors.$main-accent;
        @include colors.glow;
    }
}

#break-host {
    position: absolute;
    top: 740px;
    background: url(../resources/host/break-host-bg.png);
    width: 210px;
    height: 34px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .break-host-name {
        width: 184px;
        height: 17px;
        font-family: 'edit-undo';
        font-size: 21px;
        color: colors.$break-accent;
        text-align: center;
        text-transform: uppercase;
        overflow: hidden;
    }
    .line {
        width: 180px;
        position: absolute;
        height: 1px;
        right: 2px;
        &.line-1 {
            top: 14px;
            background-color: #091E31;
        }
        &.line-2 {
            top: 17px;
            background-color: #0A1D30;
        }
        &.line-3 {
            top: 20.5px;
            background-color: #0B1C2E;
        }
    }
}